import { useLocation } from "react-router";
import { Helmet } from "react-helmet";

export const ProductSelection = () => {
  const currentLocation = useLocation();
  return (
    <>
      <Helmet>
        <title>Hjælp til Visma Enterprises produkter</title>
      </Helmet>
      <div className="flex flex-col p-16 space-y-4 place-items-center bg-slate-50 text-center">
        <h1 className="m-0 inline-block text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight">Hvilket produkt ønsker du hjælp til?</h1>
      </div>
      <div className="flex flex-row flex-wrap place-content-center gap-x-8 gap-y-8 xl:gap-y-10 max-w-7xl mx-auto py-16 px-4 sm:px-6 md:px-8">
        <a className="group w-72 min-w-fit p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex xl:flex-col items-center gap-6 hover:shadow-xl transition-all" href={currentLocation.pathname + "datahub"}>
          <figure className="text-2xl text-center">
            <img className="w-16" src="/datahub.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">Datahub</h2>
          </div>
        </a>
        <a className="group w-72 min-w-fit p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex xl:flex-col items-center gap-6 hover:shadow-xl transition-all" href={currentLocation.pathname + "vismahr"}>
          <figure className="text-2xl text-center">
            <img className="w-16" src="/visma-hr.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">Visma HR & <br />My Visma</h2>
          </div>
        </a>
        <a className="group w-72 min-w-fit p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex xl:flex-col items-center gap-6 hover:shadow-xl transition-all" href={currentLocation.pathname + "vismaloen"}>
          <figure className="text-2xl text-center">
            <img className="w-16" src="/visma-lon.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">Visma Løn</h2>
          </div>
        </a>
        <a className="group w-72 min-w-fit p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex xl:flex-col items-center gap-6 hover:shadow-xl transition-all" href="/vismatime/all/00001">
          <figure className="text-2xl text-center">
            <img className="w-16" src="/visma-time.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">Visma Time</h2>
          </div>
        </a>
        <a className="group w-72 min-w-fit p-8 bg-white shadow-lg ring-1 ring-black/5 rounded-xl flex xl:flex-col items-center gap-6 hover:shadow-xl transition-all" href="https://easycruit.com/helpcenter/">
          <figure className="text-2xl text-center">
            <img className="w-16" src="/easycruit.svg" alt="" />
          </figure>
          <div className="text-slate-700 text-sm sm:text-base text-center group-hover:text-ve-cyan-500 transition-all">
            <h2 className="text-2xl mb-2 font-medium">EasyCruit</h2>
          </div>
        </a>
      </div>
    </>
  );
}

