export const Feedback = () => {
    var urlEscaped = encodeURIComponent(window.location.href);
    var feedbackURL = `https://docs.google.com/forms/d/e/1FAIpQLSeyahHncT2fZnUkEmW5rYdAoq2hHYRr_bkvrHazvGobri94eA/viewform?usp=pp_url&entry.537358490=${urlEscaped}&entry.1991306598=`;

    return (
        <footer className="rounded block border-4 border-double border-slate-200 bg-slate-50 p-8 pt-0 print:hidden">
            <div className="prose prose-slate max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4">
                <section>
                    <header>
                        <h3>Hvad syntes du om vejledningen?</h3>
                    </header>
                    <a className="mr-1 px-3 py-2 rounded no-underline bg-slate-100 hover:bg-slate-200 active:bg-slate-300" rel="noreferrer" href={feedbackURL + "👍"}>👍</a>
                    <a className="mr-1 px-3 py-2 rounded no-underline bg-slate-100 hover:bg-slate-200 active:bg-slate-300" rel="noreferrer" href={feedbackURL + "🤷"}>🤷</a>
                    <a className="mr-1 px-3 py-2 rounded no-underline bg-slate-100 hover:bg-slate-200 active:bg-slate-300" rel="noreferrer" href={feedbackURL + "👎"}>👎</a>
                </section>
                <section>
                    <header>
                        <h3>Stadig brug for hjælp?</h3>
                    </header>
                    <nav>
                        <a className="inline-block mr-6 no-underline hover:underline hover:text-ve-cyan-500 underline-offset-4 decoration-4 hover:decoration-ve-cyan-500/20" href="https://vismaenterpriseas.my.site.com/s/contactuspublic?utm_source=helpcenter&utm_medium=feedback_footer&utm_campaign=still_need_help">🗨 Kontakt support</a>
                    </nav>
                </section>
            </div>
        </footer>
    );
}